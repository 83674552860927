@use "@styles/helpers" as *;

.navButton {
    @include flex(center, center);
  
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: $color-primary;
    font-weight: 700;
    outline: 0;
    z-index: 1;
    cursor: pointer;
    background-color: $color-white;
    border: 1px solid $color-white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
    transition: all .2s cubic-bezier(.33,1,.68,1);

    @include with-hocus($hover: true, $focus: false) {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
    }
}

.prev {
    left: -8px;
    right: auto;

    &:hover {
        transform: translateX(-2px);
    }

    .icon {
        left: -1px;
    }
}

.next {
    right: -8px;
    left: auto;

    &:hover {
        transform: translateX(2px);
    }

    .icon {
        right: -1px;
    }
}

.icon {
    position: relative;
}