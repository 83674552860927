@use "styles/helpers" as *;

.root {
    position: relative;
    height: 100%;
}

.slidesWrapper {
    overflow: hidden;
    height: 100%;
}

.slides {
    height: 100%;
    display: flex;
    gap: var(--carousel-slides-gap); 
}

.slide {
    min-width: 0;
    flex-shrink: 0;
}

.slide > div {
    height: 100%;
}

