@use "@styles/helpers" as *;

.root {
    background-color: $color-white;
    box-shadow: 0 2px 10px rgb(0 0 0 / 12%);
    border-radius: 0 0 8px;
    z-index: 0;

    &.entering,
    &.entered {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
    }

    &.exited,
    &.exiting {
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
    }

    &.exited {
        visibility: hidden;
    }
}

.scrollbar {
    position: relative;
    max-height: 100%;
    height: 100%;
}

.inner {
    @include flex(flex-start, space-between, row);

    padding: 16px 32px;

    &.entering,
    &.entered {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.2s ease-in-out 0.2s, transform 0.3s cubic-bezier(0.33, 1, 0.68, 1) 0.2s;
    }

    &.exited,
    &.exiting {
        opacity: 0;
        transform: translateX(-20px);
        transition: opacity 0.2s ease-in-out, transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    }
}

// Menu

.menu {
    flex: 3;

    .list {
        column-count: 3;
        column-gap: 48px;
        column-rule: 1px solid $color-gray-light;
    }

    .item {
        break-inside: avoid;
    }

    .link {
        @include typography(body-500);

        padding: 8px 0;
    }

    .subLink {
        @include typography(body-small);

        padding: 4px 0;

        &.last {
            margin-bottom: 8px;
        }
    }

    @include breakpoint(1500px, max) {
        .list {
            column-count: 2;
        }
    }

    @include breakpoint(1400px, max) {
        .list {
            column-count: 3;
        }
    }
}


// Banner Image

.imageContainer {
    @include flex(center, flex-end, row);

    padding-top: 16px;
    padding-left: 16px;
    flex: 1;

    // Image should be 274px x 484px in size
    max-width: 484px;
    
    @include breakpoint(1400px, max) {
        display: none;
    }
    
    .image {
        cursor: pointer;
        border-radius: 8px;
    }
}
