@use "@styles/helpers" as *;

.root {
    max-width: 100%;
    height: auto;

    .navButton {
        top: 0;
        bottom: 0;
        width: 30px;
        height: 30px;
        margin: auto;
        background-color: $color-gray-light;
        border: none;
        transform: none;
        z-index: 2;

        .iconWrapper {
            background-color: $color-gray-light;
            border-color: $color-gray-light;
            box-shadow: none !important;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            width: 16px;
            height: 100%;
        }

        &:hover {
            .iconWrapper {
                background-color: $color-primary;
                border-color: $color-primary;
                color: $color-white;
            }
        }

        &.prev {
            left: 0;
            bottom: 1px;

            &:before {
                right: -16px;
                background: linear-gradient(to right, white, transparent);
            }
        }

        &.next {
            right: 0;
            bottom: 1px;

            &:before {
                left: -16px;
                background: linear-gradient(to left, white, transparent);
            }
        }
    }


    .menuItem {
        @include flex(center, center);
        
        margin-left: 0;
    }

    .menuItemLink {
        @include typography(body-small);

        padding: 4px 12px;
        transition: background-color .2s ease;

        &:hover {
            color: $color-primary-shade-2;
            background: $color-gray-light;
        }

        &.active {
            color: $color-primary;
            background: $color-gray-light;
        }

        .icon {
            max-width: 20px;
            max-height: 20px;
            margin-right: 8px;
            margin-bottom: 2px;
        }
    }
}