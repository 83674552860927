@use "styles/helpers" as *;

.emblaProgress {
    border-radius: 0 0 8px 8px;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    background-color: $color-gray-light;
    position: relative;
    height: 8px;
    place-self: center flex-end;
    width: 100%;
    overflow: hidden;
}

.emblaProgressBar {
    background-color: $color-gray;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: -100%;
}
