@use "@styles/helpers" as *;

.pagination {
    height: 4px;
    @include flex(center, center);

    gap: 5px
}

.dot {
    background: $color-secondary-shade-2;
    height: 100%;
    width: 10px;
    border-radius: 2px;
    border: none;
    transition: width 0.4s ease, background 0.4s ease;
    transform-origin: 50% 50%;
    cursor: pointer;
}

.active {
    background: $color-primary;
    transition: width 0.4s ease, background 0.4s ease;
    width: 24px;
    border-radius: 10px;
}